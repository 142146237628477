import { Flex } from '@applyboard/crystal-ui';
import { BackButton } from '~/components/BackButton';

import {
  ProgramDetails,
  ApplicationDetailsHeading,
  StatusAndCitizenship,
  ContactInformation,
  ApplicantInformation,
  EducationHistory,
  LanguageProficiency,
  OtherRequiredDocuments,
  ApplicationProcessing,
  CardControlActions,

  // processing
  AdditionalDocumentNotice,
  DeferralRequestNotice,
} from './../components';

export function MobileLayout() {
  return (
    <div aria-label="mobile" style={{ marginBottom: '10px' }}>
      <Flex pt={10} pb={2} px={8} gap={6} direction="column">
        <BackButton title="Applications List" href="/applications" />
      </Flex>
      <Flex px={8} gap={6} direction="column">
        <Flex justify="between" align="stretch" direction="column">
          <ApplicationDetailsHeading />
          <ApplicationProcessing />
        </Flex>
        <DeferralRequestNotice />
        <AdditionalDocumentNotice />
        <ProgramDetails lineSplit={true} />
        <CardControlActions />
        <Flex gap={8} align="stretch" direction="column">
          <Flex gap={8} direction="column">
            <ApplicantInformation />
            <ContactInformation />
            <StatusAndCitizenship />
            <EducationHistory />
            <LanguageProficiency />
            <OtherRequiredDocuments />
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
}
