import { Flex, Text, TextProps } from '@applyboard/crystal-ui';

type LabelValueProps = {
  label: string;
  variant?: TextProps['variant'];
  color?: TextProps['intent'];
  icon?: React.ReactNode;
  value: string | React.ReactNode;
};
export function LabelValue(props: LabelValueProps) {
  const { label, variant = 'bodyM', color, value, icon } = props;
  return (
    <Flex direction="column" aria-describedby={label} role="term">
      <Text id={label} variant="labelS" contrast="low">
        {label.toUpperCase()}
      </Text>
      <Flex gap={1} align="center">
        {icon}
        <Text variant={variant} intent={color}>
          {value}
        </Text>
      </Flex>
    </Flex>
  );
}
