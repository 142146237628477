import { Flex, Text } from '@applyboard/crystal-ui';
import { DocumentOutlineIcon } from '@applyboard/ui-icons';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';

import { Card } from './Card';
import { AttachedDocuments } from './shared/AttachedDocuments';
import { AdditionalOtherDocumentRequest } from '../ApplicationProcessing/AdditionalDocumentRequest/AdditionalOtherDocumentRequest';

export function OtherRequiredDocuments() {
  const {
    application: { supportingDocuments },
  } = useAppProcessing();
  return (
    <Card id="supportingDocuments" icon={DocumentOutlineIcon} title="Other Required Documents">
      <Flex justify="start" direction="column" gap={8}>
        {supportingDocuments.length === 0 ? (
          <Text variant="bodyM" contrast="low">
            Not provided
          </Text>
        ) : (
          <AttachedDocuments
            showTitle
            attachmentDocuments={supportingDocuments}
            section="supportingDocuments"
            sectionReference="supportingDocuments"
            disableAdditionalRequest
            noRequestForMissingDocuments
          />
        )}
        <AdditionalOtherDocumentRequest />
      </Flex>
    </Card>
  );
}
