import { Flex, Text, Tag } from '@applyboard/crystal-ui';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';

export function ApplicationDetailsHeading() {
  const { application } = useAppProcessing();

  return (
    <Flex direction="column">
      <Flex pl={4} justify="between" align="center">
        <Flex align="center" gap={4} pb={2}>
          <Text align="left" contrast="high" intent="secondary" variant="headlineM" overflow="nowrap">
            Application Details
          </Text>
          <Tag icon="fill" intent={application.status.intent} size="md">
            {application.status.label}
          </Tag>
        </Flex>
      </Flex>
    </Flex>
  );
}
