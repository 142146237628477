import React from 'react';
import { Button, Dialog, Flex, Notice, Textarea } from '@applyboard/crystal-ui';
import { useAdditionalDocumentRequest } from '~/pages/ApplicationDetails/context';
import { AttachmentTypeCode } from '~/pages/ApplicationDetails/data/useApplication';

type SingleDocumentRequestProps = {
  notice?: string;
  mode: 'ADD' | 'UPDATE';
  documentTypeName: string;
  sectionReference: string;
  documentTypeCode: AttachmentTypeCode;
};

export function SingleDocumentRequest(props: SingleDocumentRequestProps) {
  const [open, setOpen] = React.useState(false);

  // TECH-DEBT: find a better way to handle this
  const documentTypeName = ['LANGUAGE_TEST_IELTS', 'LANGUAGE_TEST_PTE', 'LANGUAGE_TEST_TOEFL'].includes(
    props.documentTypeCode
  )
    ? 'Language Test'
    : props.documentTypeName;

  return (
    <>
      <Button
        intent="primary"
        onClick={() => setOpen(true)}
        emphasis={props.mode === 'ADD' ? 'outlined' : 'transparent'}
        size={props.mode === 'ADD' ? 'md' : 'lg'}
      >
        {props.mode === 'ADD' ? `Request ${props.documentTypeName}` : 'Request Document'}
      </Button>
      {open && (
        <SingleDocumentRequestDialog
          notice={props.notice}
          onClose={() => setOpen(false)}
          documentTypeName={documentTypeName}
          documentTypeCode={props.documentTypeCode}
          sectionReference={props.sectionReference}
        />
      )}
    </>
  );
}

type SingleDocumentRequestDialogProps = {
  onClose: () => void;
  notice?: string;
  documentTypeName: string;
  sectionReference: string;
  documentTypeCode: AttachmentTypeCode;
};
function SingleDocumentRequestDialog(props: SingleDocumentRequestDialogProps) {
  const { documentTypeCode: type, sectionReference, onClose } = props;
  const { addUpdateDocuments, documents } = useAdditionalDocumentRequest();

  const activeDocumentReq = React.useMemo(
    () => documents.find((f) => f.sectionReference === sectionReference && f.type === type),
    [documents, type, sectionReference]
  );
  const [comment, setComment] = React.useState(() => activeDocumentReq?.comment || '');

  const onSave = React.useCallback(() => {
    addUpdateDocuments([{ type, comment, sectionReference }]);
    onClose();
  }, [addUpdateDocuments, comment, sectionReference, type, onClose]);

  return (
    <Dialog
      open={true}
      onOpenChange={(open) => {
        if (!open) props.onClose();
      }}
    >
      <Dialog.Heading>{`Request ${props.documentTypeName}`}</Dialog.Heading>
      <Dialog.Content>
        <Flex gap={6} direction="column">
          {props.notice && <Notice intent="secondary">{props.notice}</Notice>}
          <Textarea label="Notes for student" rows={2} value={comment} onChange={(value) => setComment(value)} />
        </Flex>
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.CloseButton width="fill">Cancel</Dialog.CloseButton>
        <Button disabled={!comment.trim()} intent="primary" width="fill" onClick={() => onSave()}>
          Save
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}
