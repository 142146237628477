import React from 'react';
import { Flex } from '@applyboard/crystal-ui';
import { LabelValue } from '~/components/uie-fix/LabelValue';
import { useAdditionalDocumentRequest } from '~/pages/ApplicationDetails/context';
import { Attachment, AttachmentTypeCode } from '~/pages/ApplicationDetails/data/useApplication';

import { AttachedDocument } from './AttachedDocument';
import { SingleDocumentRequest } from '../../ApplicationProcessing/AdditionalDocumentRequest/SingleDocumentRequest';

type AttachedDocumentsProps = {
  showTitle?: boolean;
  section: Section;
  sectionReference: string;
  attachmentDocuments: Array<Attachment>;
  noRequestForMissingDocuments?: boolean;
  types?: AttachmentTypeCode[];
  disableAdditionalRequest?: boolean;
};
export function AttachedDocuments(props: AttachedDocumentsProps) {
  const { showTitle, attachmentDocuments } = props;
  const additionalDocumentRequest = useAdditionalDocumentRequest();

  const sectionsDocTypeCodes = React.useMemo(() => {
    return Object.entries(mappings)
      .filter(
        ([k, v]) =>
          v &&
          v.section === props.section &&
          (!props.types || (props.types && props.types.includes(k as AttachmentTypeCode)))
      )
      .map(([k]) => k as Attachment['type']['code']);
  }, [props.section, props.types]);

  const documents = React.useMemo(() => {
    return sectionsDocTypeCodes.flatMap((docTypeCode) => {
      const attachmentDocumentsForType = attachmentDocuments.filter((doc) => doc.type.code === docTypeCode);
      return attachmentDocumentsForType.map((attachmentDocument) => ({
        document: attachmentDocument,
        type: { ...mappings[docTypeCode], code: docTypeCode },
      }));
    });
  }, [attachmentDocuments, sectionsDocTypeCodes]);

  const groupsByTypeCode = React.useMemo(() => {
    const groups = {} as Record<AttachmentTypeCode, { name: string; code: string; documents: Attachment[] }>;

    documents
      .filter((d) => !!d.document) // filter out undefined documents
      .forEach((doc) => {
        if (!groups[doc.type.code]) {
          groups[doc.type.code] = {
            documents: [],
            code: doc.type.code,
            name: doc.type.name,
          };
        }
        if (doc.document) groups[doc.type.code].documents.push(doc.document);
      });

    return Object.values(groups);
  }, [documents]);

  const nonExistenaceDocuments = React.useMemo(() => {
    // we only care for non existence documents when additional request is enabled
    if (!additionalDocumentRequest.isEnabled || props.disableAdditionalRequest) return [];

    return sectionsDocTypeCodes
      .filter((s) => !documents.some((doc) => doc.type.code === s))
      .map((type) => ({
        code: type,
        name: mappings[type].name,
      }));
  }, [documents, additionalDocumentRequest, props.disableAdditionalRequest, sectionsDocTypeCodes]);

  return (
    <>
      {groupsByTypeCode.map((group) => (
        <Flex key={group.code} gap={6} direction="column">
          {showTitle && <LabelValue variant="bodyL" label="SUPPORTING DOC" value={group.name} />}
          {group.documents.map((doc) => (
            <AttachedDocument
              attachmentDocument={doc}
              key={doc.key}
              disableAdditionalRequest={props.disableAdditionalRequest}
            />
          ))}
        </Flex>
      ))}
      {!props.noRequestForMissingDocuments &&
        nonExistenaceDocuments.map((missingDoc) => (
          <SingleDocumentRequest
            key={missingDoc.code}
            mode="ADD"
            documentTypeCode={missingDoc.code}
            sectionReference={props.sectionReference}
            documentTypeName={missingDoc.name}
          />
        ))}
    </>
  );
}

type Section =
  | 'application'
  | 'applicant'
  | 'applicationFee'
  | 'agency'
  | 'programIntake'
  | 'statusAndCitizenship'
  | 'languageProficiency'
  | 'educationHistory'
  | 'supportingDocuments';
const mappings: Record<
  AttachmentTypeCode,
  {
    name: string;
    section: Section;
  }
> = {
  LANGUAGE_TEST: { name: 'IELTS Language test', section: 'languageProficiency' },
  LANGUAGE_TEST_IELTS: { name: 'IELTS Language test', section: 'languageProficiency' },
  LANGUAGE_TEST_PTE: { name: 'PTE Language test', section: 'languageProficiency' },
  LANGUAGE_TEST_TOEFL: { name: 'TOEFL Language test', section: 'languageProficiency' },

  TRANSCRIPTS: { name: 'Transcript', section: 'educationHistory' },
  CERTIFICATE: { name: 'Certificate', section: 'educationHistory' },

  PASSPORT: {
    name: 'Passport',
    section: 'statusAndCitizenship',
  },
  DESTINATION_COUNTRY_STATUS: {
    name: 'Destination country status',
    section: 'statusAndCitizenship',
  },
  STUDY_PERMIT: { name: 'Study permit', section: 'statusAndCitizenship' },
  WORK_PERMIT: { name: 'Work permit', section: 'statusAndCitizenship' },
  VISITOR_TOURIST_VISA: { name: 'Visitor tourist visa', section: 'statusAndCitizenship' },

  STATEMENT_OF_PURPOSE: { name: 'Statement of purpose', section: 'supportingDocuments' },
  LETTER_OF_RECOMMENDATION: {
    name: 'Letter of recommendation',
    section: 'supportingDocuments',
  },
  CONSENT_FORM: { name: 'Consent form', section: 'supportingDocuments' },
  GMAT_SCORE: { name: 'GMAT score', section: 'supportingDocuments' },
  GRE_SCORE: { name: 'GRE score', section: 'supportingDocuments' },
  RESUME: { name: 'Resume', section: 'supportingDocuments' },
  EMPLOYMENT_LETTER: { name: 'Employment letter', section: 'supportingDocuments' },
  VOLUNTEER_LETTER: { name: 'Volunteer letter', section: 'supportingDocuments' },
  ADDITIONAL_DOCUMENT: { name: 'Additional document', section: 'supportingDocuments' },

  PRE_OFFER_LETTER: { name: 'Pre-offer Letter', section: 'application' },
  REJECTION_LETTER: { name: 'Rejection Letter', section: 'application' },
};
