import React from 'react';
import { useAdditionalDocumentRequest } from '~/pages/ApplicationDetails/context';
import { Flex, Dialog, Button, CheckboxGroup, Textarea } from '@applyboard/crystal-ui';
import { AttachmentTypeCode } from '~/pages/ApplicationDetails/data/useApplication';

const OPTIONS: Readonly<Array<{ label: string; key: AttachmentTypeCode }>> = [
  { label: 'Statement of Purpose', key: 'STATEMENT_OF_PURPOSE' },
  { label: 'Letter of Recommendation', key: 'LETTER_OF_RECOMMENDATION' },
  { label: 'Consent Form', key: 'CONSENT_FORM' },
  { label: 'GMAT Score', key: 'GMAT_SCORE' },
  { label: 'GRE Score', key: 'GRE_SCORE' },
  { label: 'Resume', key: 'RESUME' },
  { label: 'Employment Letter', key: 'EMPLOYMENT_LETTER' },
  { label: 'Volunteer Letter', key: 'VOLUNTEER_LETTER' },
  { label: 'Other', key: 'ADDITIONAL_DOCUMENT' },
] as const;

export function AdditionalOtherDocumentRequest() {
  const [open, setOpen] = React.useState(false);
  const { isEnabled } = useAdditionalDocumentRequest();

  if (!isEnabled) return null;

  return (
    <>
      <Button intent="primary" emphasis="outlined" onClick={() => setOpen(true)}>
        Request Additional Documents
      </Button>
      {open && <AdditionalOtherDocumentRequestDialog onClose={() => setOpen(false)} />}
    </>
  );
}

export function AdditionalOtherDocumentRequestDialog(props: { onClose: () => void }) {
  const { documents, setDocuments } = useAdditionalDocumentRequest();

  const [options, setOptions] = React.useState<{ [key: string]: { enabled: boolean; comment: string } }>(() => {
    const existingDocuments = documents.filter((doc) => doc.sectionReference === 'other');
    return OPTIONS.reduce((acc, option) => {
      const existingDocument = existingDocuments.find((s) => s.type === option.key);
      return {
        ...acc,
        [option.key]: existingDocument
          ? { enabled: true, comment: existingDocument.comment }
          : { enabled: false, comment: '' },
      };
    }, {});
  });

  const checkedItems = React.useMemo(
    () =>
      Object.entries(options)
        .filter(([, v]) => v.enabled)
        .map(([k]) => k),
    [options]
  );

  function onSave() {
    const updatedDocuments = documents.filter((doc) => doc.sectionReference !== 'other');
    setDocuments([
      ...updatedDocuments,
      ...Object.entries(options)
        .filter(([, option]) => option.enabled)
        .map(([key, option]) => ({
          comment: option.comment,
          type: key as AttachmentTypeCode,
          sectionReference: 'other',
        })),
    ]);
    props.onClose();
  }

  const disableSave = React.useMemo(() => {
    return Object.entries(options).some(([, v]) => v.enabled && !v.comment);
  }, [options]);

  return (
    <Flex justify="start" direction="column" gap={8}>
      <Dialog
        open={true}
        onOpenChange={(open) => {
          if (!open) props.onClose();
        }}
      >
        <Dialog.Heading>Request Other Documents</Dialog.Heading>
        <Dialog.Content>
          <CheckboxGroup
            onChange={(requestedDocs) => {
              setOptions(
                requestedDocs.reduce(
                  (acc, item) => ({
                    ...acc,
                    [item]: { ...options[item], enabled: true },
                  }),
                  {}
                )
              );
            }}
            value={checkedItems}
          >
            <Flex direction="column">
              {OPTIONS.map((option) => (
                <Flex key={`flex-${option.key}`} direction="column" gap={2}>
                  <CheckboxGroup.Option label={option.label} value={option.key} key={`checkbox-${option.key}`} />
                  {checkedItems.includes(option.key) && (
                    <Textarea
                      label="Notes for student"
                      key={`textinput-${option.key}`}
                      value={options[option.key].comment || ''}
                      onChange={(value) => setOptions({ ...options, [option.key]: { enabled: true, comment: value } })}
                      rows={2}
                    />
                  )}
                </Flex>
              ))}
            </Flex>
          </CheckboxGroup>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.CloseButton width="fill">Cancel</Dialog.CloseButton>
          <Button intent="primary" width="fill" onClick={onSave} disabled={disableSave}>
            Save
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Flex>
  );
}
