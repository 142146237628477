import flags from 'country-flag-icons/react/3x2';

type FlagKey = keyof typeof flags;

export function Flag({ nationalityCode, height = 20 }: { nationalityCode: string; height?: number }) {
  const Flag = flags[nationalityCode.toLocaleUpperCase() as FlagKey];
  if (Flag)
    return <Flag role="figure" aria-describedby={nationalityCode} height={height} aria-label={nationalityCode} />;
  else return null;
}
